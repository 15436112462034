<template>
    <div class="search-bubble-container">
        <img class="search-icon" src="@assets/img/icons/search.svg">
        <input
            type="text"
            placeholder="Find Memos..."
            @keyup.enter="(event) => search(event.target.value)"
        >
    </div>
</template>

<script>
export default {
    name: "SearchBubble",
    methods: {
        search(searchString) {
            if (searchString != "" && (searchString != this.$route.params.searchString)) {
                if (this.$route.name.includes("web-app-search")) {
                    this.$router.push({ name: this.$route.name, params: { searchString } });
                } else {
                    this.$router.push({ name: "web-app-search", params: { searchString } });
                }
            }
        }
    }
}
</script>

<style lang="scss" scoped>
.search-bubble-container {
    position: fixed;
    left: 15px;
    top: 15px;
    background-color: #2C2C2E;
    border-radius: 50px;
    z-index: 20;

    &:hover {
        background-color: lighten(#2C2C2E, 10%);
    }

    .search-icon {
        position: absolute;
        z-index: 1;
        left: 15px;
        top: 50%;
        transform: translateY(-50%);
    }

    input {
        z-index: 2;
        position: relative;
        width: 50px;
        height: 50px;
        background-color: transparent;
        box-shadow: none;
        border: 0;
        transition: width .3s;
        padding-left: 50px;
        font-size: 16px;
        color: white;

        &::placeholder {
            color: transparent;
        }

        &:focus {
            width: 300px;
            border: 0;

            &::placeholder {
                color: rgba(235, 235, 245, 0.3);
            }
        }

        &:focus-visible {
            outline: 0;
        }
    }
}
</style>
